import type { OpeningHoursOpenClose } from '$lib/graphql/types'
import {
  addHours,
  compareAsc,
  differenceInMinutes,
  format,
  isSameDay,
  isValid,
  parse,
} from 'date-fns'

import { range } from 'lodash-es'

function getNumDaysInMonth(month: number, year: number) {
  const numDaysInMonthLookUp = {
    0: 31,
    1: year % 4 == 0 ? 29 : 28,
    2: 31,
    3: 30,
    4: 31,
    5: 30,
    6: 31,
    7: 31,
    8: 30,
    9: 31,
    10: 30,
    11: 31,
  }
  return numDaysInMonthLookUp[month]
}

const monthNames = {
  0: 'January',
  1: 'February',
  2: 'March',
  3: 'April',
  4: 'May',
  5: 'June',
  6: 'July',
  7: 'August',
  8: 'September',
  9: 'October',
  10: 'November',
  11: 'December',
}

export const monthNamesShort = {
  0: 'Jan',
  1: 'Feb',
  2: 'Mar',
  3: 'Apr',
  4: 'May',
  5: 'Jun',
  6: 'Jul',
  7: 'Aug',
  8: 'Sep',
  9: 'Oct',
  10: 'Nov',
  11: 'Dec',
}

export const dayofWeekNameShort = {
  0: 'Su',
  1: 'Mo',
  2: 'Tu',
  3: 'We',
  4: 'Th',
  5: 'Fr',
  6: 'Sa',
}

export const dayofWeekNameLong = {
  0: 'Sunday',
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
}

export type DayInCalendarSelect = {
  date: Date
  isCurrentMonth: boolean
  isToday: boolean
  isPast: boolean
}

export type MonthInCalendarSelect = {
  name: string
  days: DayInCalendarSelect[]
  year: number
}

export function listCalDates(now: Date) {
  // standard time attributes
  const currentYear = now.getFullYear()
  const currentMonth = now.getMonth() ?? 12
  // const currentDate = now.getDate()

  const resMonths: MonthInCalendarSelect[] = []
  resMonths.push({
    name: monthNames[currentMonth],
    days: calculateDaysForMonth(currentYear, currentMonth, now, true),
    year: currentYear,
  })
  range(currentMonth + 1, 12)?.forEach((month) => {
    resMonths.push({
      name: monthNames[month],
      days: calculateDaysForMonth(currentYear, month, now, false),
      year: currentYear,
    })
  })
  range(0, currentMonth + 1)?.forEach((month) => {
    resMonths.push({
      name: monthNames[month],
      days: calculateDaysForMonth(currentYear + 1, month, now, false),
      year: currentYear + 1,
    })
  })

  return resMonths
}

function calculateDaysForMonth(
  currentYear: number,
  month: number,
  now: Date,
  checkIfPast: boolean,
) {
  const firstDayInstance = new Date(currentYear, month, 1)
  const firstDay = firstDayInstance.getDay()
  const days: DayInCalendarSelect[] = []

  // set the days for the last month to fill out the row
  let lastYear = currentYear
  let lastMonth = month - 1
  if (month === 0) {
    lastYear = currentYear - 1
    lastMonth = 11
  }
  const numDays = getNumDaysInMonth(lastMonth, lastYear) ?? 0

  range(numDays - firstDay + 1, numDays + 1)?.forEach((day) => {
    const dayInstance = new Date(lastYear, lastMonth, day)
    days.push({
      date: dayInstance,
      isCurrentMonth: false,
      isToday: false,
      isPast: false,
    })
  })
  // set the days for the month
  let dayCounter = new Date(currentYear, month, 1)
  range(1, getNumDaysInMonth(month, currentYear) + 1)?.forEach(() => {
    if (checkIfPast) {
      days.push({
        date: dayCounter,
        isCurrentMonth: true,
        isToday: isSameDay(now, dayCounter),
        isPast: isPastInSameMonth(now, dayCounter),
      })
    } else {
      days.push({
        date: dayCounter,
        isCurrentMonth: true,
        isToday: isSameDay(now, dayCounter),
        isPast: false,
      })
    }
    dayCounter = new Date(
      dayCounter?.getFullYear(),
      dayCounter?.getMonth(),
      dayCounter?.getDate() + 1,
    )
  })
  // set the remaining days to make 42 total
  if (42 - days.length > 0) {
    let nextYear = currentYear
    let nextMonth = month + 1
    if (month === 11) {
      nextYear = currentYear + 1
      nextMonth = 0
    }

    range(1, 42 - days.length + 1)?.forEach((day) => {
      const dayInstance = new Date(nextYear, nextMonth, day)
      days.push({
        date: dayInstance,
        isCurrentMonth: false,
        isToday: false,
        isPast: false,
      })
    })
  }
  return days
}

export function isPastInSameMonth(now: Date, dateToCompare: Date) {
  const currentYear = now.getFullYear()
  const currentMonth = now.getMonth()
  const currentDate = now.getDate()
  const year = dateToCompare.getFullYear()
  const month = dateToCompare.getMonth()
  const date = dateToCompare.getDate()
  return date < currentDate && month === currentMonth && year === currentYear
}

export function setMonthOffset(now: Date, dateToCompare: Date) {
  const currentMonth = now?.getMonth()
  const month = dateToCompare?.getMonth()

  if (currentMonth > month) return 12 - currentMonth + month
  return month - currentMonth
}

export function writeDateString(date: Date) {
  if (!date) return ''
  return (
    date.getFullYear() +
    '-' +
    (date.getMonth() + 1).toString().padStart(2, '0') +
    '-' +
    date.getDate().toString().padStart(2, '0')
  )
}

export function isBetween(startDate: Date, currentDate: Date, endDate: Date) {
  return compareAsc(currentDate, startDate) === 1 && compareAsc(currentDate, endDate) === -1
}

interface funcProps {
  offsetInMinutes: number
  durationInMinutes?: number
  startDate: Date
  returnString?: boolean
}

export function calculateDateFromOffset({
  offsetInMinutes,
  durationInMinutes,
  startDate,
  returnString = true,
}: funcProps) {
  let date = new Date(startDate)
  date = addHours(date, offsetInMinutes / 60)
  if (durationInMinutes) {
    date = addHours(date, durationInMinutes / 60)
  }
  if (returnString) return date.toDateString()
  return date
}

export function formatDateRangeShort(start: Date, end: Date) {
  if (start == null) return ''
  if (end == null) return ''
  if (!isValid(start)) return ''
  if (!isValid(end)) return ''

  if (start?.getMonth() == end?.getMonth()) {
    return format(start, 'MMM d') + ' - ' + format(end, 'd')
  } else {
    return format(start, 'MMM d') + ' - ' + format(end, 'MMM d')
  }
}

export function formatDateRangeShortWithString(start: string, end: string) {
  if (start == null || start == '') return ''
  if (end == null || end == '') return ''
  // if (!isValid(start)) return ''
  // if (!isValid(end)) return ''

  const startSplit = start.split('T')?.[0]?.split('-')
  const endSplit = end.split('T')?.[0]?.split('-')

  const startMonth = monthNamesShort[Number(startSplit?.[1] ?? 1) - 1]
  const endMonth = monthNamesShort[Number(endSplit?.[1] ?? 1) - 1]

  const startDay = startSplit?.[2]?.startsWith('0') ? startSplit?.[2]?.[1] : startSplit?.[2]
  const endDay = endSplit?.[2]?.startsWith('0') ? endSplit?.[2]?.[1] : endSplit?.[2]

  if (startMonth == endMonth) {
    return `${startMonth} ${startDay} - ${endDay}`
  } else {
    return `${startMonth} ${startDay} - ${endMonth} ${endDay}`
  }
}

export function formatDateRangeShortWithYear(start: Date, end: Date) {
  if (start == null) return ''
  if (end == null) return ''
  if (start?.getMonth() == end?.getMonth()) {
    return format(start, 'MMM d') + ' - ' + format(end, 'd, yyyy')
  } else if (start.getFullYear() == end.getFullYear()) {
    return format(start, 'MMM d') + ' - ' + format(end, 'MMM d, yyyy')
  } else {
    return format(start, 'MMM d, yyyy') + ' - ' + format(end, 'MMM d, yyyy')
  }
}

export function formatDateShort(start: Date) {
  if (start == null) return ''
  return format(start, 'MMM d')
}

export function formatDateLong(start: Date) {
  if (start == null) return ''
  return format(start, 'MMM d, yyyy')
}

export function periodsPartiallyEqual(a: OpeningHoursOpenClose, b: OpeningHoursOpenClose) {
  const timeA = parse(a?.time, 'HH:mm', new Date())
  const timeB = parse(b?.time, 'HH:mm', new Date())
  return differenceInMinutes(timeA, timeB) <= 1
}

export function formatTimeLocale(time: string) {
  if (time == null) return ''
  const locale = new Intl.NumberFormat().resolvedOptions().locale
  const formatter = new Intl.DateTimeFormat(locale, { dateStyle: undefined, timeStyle: 'short' })
  const parts = formatter.format(parse(time, 'HH:mm', new Date()))
  return parts
}

export function formatMilitaryTime(time: string) {
  const times = time.split(':')
  const hours = Number(times[0])
  const minutes = Number(times[1])

  let timeValue = ''

  if (hours > 0 && hours <= 12) {
    timeValue = '' + hours
  } else if (hours > 12) {
    timeValue = '' + (hours - 12)
  } else if (hours == 0) {
    timeValue = '12'
  }

  timeValue += minutes < 10 ? ':0' + minutes : ':' + minutes
  timeValue += hours >= 12 ? ' PM' : ' AM'
  if (timeValue === '12:00 AM') {
    return timeValue + ' (midnight)'
  } else if (timeValue === '12:00 PM') {
    return timeValue + ' (noon)'
  }
  return timeValue
}

export function getUTCDate(date: Date) {
  return new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000)
}
